.neuroglancer-layer-list-panel-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: 0px;
  min-height: 0px;
  flex-basis: 0px;
}

.neuroglancer-layer-list-panel-item {
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  border: 1px solid #aaa;
  margin: 2px;
  align-items: center;
  gap: 0px 2px;
}

.neuroglancer-layer-list-panel-item input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
}

.neuroglancer-layer-list-panel-eye-icon {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  display: flex;
}

.neuroglancer-layer-list-panel-item[data-selected="true"] {
  border-color: #3c3;
}

.neuroglancer-layer-list-panel-item[data-archived="true"] {
  border-color: #666;
}

.neuroglancer-layer-list-panel-item[data-archived="true"]
  .neuroglancer-layer-list-panel-item-name,
.neuroglancer-layer-list-panel-item[data-archived="true"]
  .neuroglancer-layer-type-indicator,
.neuroglancer-layer-list-panel-item[data-archived="true"]
  .neuroglancer-icon
  svg {
  color: #999;
  stroke: #999;
}

.neuroglancer-layer-list-panel-item-name {
  line-height: 20px;
  padding: 0;
  margin-left: 4px;
}

.neuroglancer-layer-list-panel-item:hover {
  background-color: #333;
}

.neuroglancer-layer-list-panel-item-number {
  font-family: sans-serif;
  color: white;
  font-weight: bold;
  display: inline-block;
  text-align: right;
  line-height: 18px;
}

.neuroglancer-layer-list-panel-item:not(:hover)
  > .neuroglancer-layer-list-panel-item-delete {
  display: none;
}

.neuroglancer-layer-list-panel-item:not(:hover)
  > .neuroglancer-layer-list-panel-item-controls {
  display: none;
}
