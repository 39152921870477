/**
 * @license
 * Copyright 2024 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-tool-palette-button-container {
  display: inline-flex;
  flex-direction: row;
}

.neuroglancer-tool-palette-button-container {
  border-radius: 20%;
  border: 1px solid transparent;
}

.neuroglancer-tool-palette-button-container:hover {
  background-color: #484848;
}

.neuroglancer-tool-palette-button {
  position: relative;
  display: flex;
  flex-direction: row;
}

.neuroglancer-tool-palette-dropdown {
  position: absolute;
  z-index: 100;
  min-width: min(50vw, 300px);
  border: 1px solid #aaa;
  background-color: black;
  padding: 2px;
}

.neuroglancer-tool-palette-name {
  flex: 1;
  background-color: transparent;
  border: 0px;
  color: white;
  outline: 0px;
  /* ensure if name gets too long it doesn't push buttons over */
  width: 0px;
  min-width: 0px;
}

.neuroglancer-tool-palette-dropdown > ul {
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.neuroglancer-tool-palette-dropdown
  > ul
  > li:not(.neuroglancer-tool-palette-dropdown-separator) {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 2px;
  font: 10pt sans-serif;
}

.neuroglancer-tool-palette-dropdown
  > ul
  > li:not(.neuroglancer-tool-palette-dropdown-separator):hover {
  background-color: #333;
}

.neuroglancer-tool-palette-dropdown-canned-item {
  cursor: pointer;
}

.neuroglancer-tool-palette-dropdown-separator {
  height: 1px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0px;
  background-color: #ccc;
}

.neuroglancer-tool-palette-drop-zone {
  display: block;
  flex: 1;
}

.neuroglancer-tool-palette-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  font: 10pt sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

.neuroglancer-tool-palette-tool-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.neuroglancer-tool-palette-tool-container:hover {
  background-color: #282828;
}

.neuroglancer-tool-palette-tool-container
  > .neuroglancer-tool-palette-tool-content {
  grid-row: 1;
  grid-column: 2;
}

.neuroglancer-tool-palette-tool-container
  > .neuroglancer-tool-palette-tool-properties {
  grid-row: 2;
  grid-column: 1/3;
}

.neuroglancer-tool-palette-tool-container > .neuroglancer-tool-key-binding {
  grid-row: 1;
  grid-column: 1;
}

.neuroglancer-tool-palette-tool-delete {
  visibility: hidden;
}

.neuroglancer-tool-palette-tool-container:hover
  > .neuroglancer-tool-palette-tool-delete {
  visibility: inherit;
}

.neuroglancer-tool-palette-layer-group-header {
  display: flex;
  flex-direction: row;
  background-color: #181818;
  border-bottom: 2px solid black;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.neuroglancer-tool-palette-layer-group:hover
  > .neuroglancer-tool-palette-layer-group-header {
  background-color: #282828;
}

.neuroglancer-tool-palette-layer-group {
  border-left: 2px solid #181818;
}

.neuroglancer-tool-palette-layer-group:hover {
  border-left: 2px solid #ccc;
}

.neuroglancer-tool-palette-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.neuroglancer-tool-palette-body .neuroglancer-multiline-autocomplete-dropdown {
  z-index: 2;
}

.neuroglancer-tool-palette-query-errors {
  margin: 0px;
  padding: 0px;
  margin-bottom: 2px;
}

.neuroglancer-tool-palette-query-errors > li {
  list-style-type: none;
  padding: 2px;
  font: 10pt sans-serif;
  color: red;
}

.neuroglancer-tool-palette-tool-properties {
  display: none;
  color: #f9f;
  font-style: italic;
  user-select: text;
}

.neuroglancer-tool-palette-show-properties
  .neuroglancer-tool-palette-tool-properties {
  display: block;
}

.neuroglancer-tool-to-be-removed {
  opacity: 50%;
}
