/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#neuroglancer-status-container {
  position: absolute;
  bottom: 0px;
  z-index: 100;
  background-color: #808080;
  color: white;
  margin: 0px;
  padding: 0px;
  font: 10pt sans-serif;
}

#neuroglancer-status-container li {
  width: 100vw;
  max-height: 25vh;
  overflow-y: auto;
}

.neuroglancer-status-header {
  display: inline-block;
  font: 10pt sans-serif;
  font-weight: bold;
  background-color: #333;
  padding: 2px;
}

#neuroglancer-status-container-modal {
  pointer-events: none;
  position: absolute;
  z-index: 100;
  color: white;
  margin: 0px;
  padding: 0px;
  font: 10pt sans-serif;

  display: grid;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 80vw;
  left: 10vw;
  grid-row-gap: 10px;
}

#neuroglancer-status-container-modal > div {
  pointer-events: initial;
  position: relative;
  background-color: #808080;
  padding: 20px;
  padding-top: 30px;
  border: 1px solid white;
}

#neuroglancer-status-container-modal > div > div:first-child {
  position: absolute;
  top: 4px;
  right: 4px;
  margin: 0;
  padding: 0;
}

#neuroglancer-status-container-modal li {
  display: block;
}
